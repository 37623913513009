import React from 'react';
import './Header.css';
import { useTranslation, Trans } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className="header">
      <div className="header-image-container clip-path">
        <img src={"/assets/images/adult-airport-arriving-1457691.jpg"} alt="Foire aux Questions" className="header-image"/>
      </div>
      <div className="header-overlay clip-path">
        <h1 className="header-title text-center">{t('faqPage.header.title')}</h1>
      </div>
    </header>
  );
}

export default Header;
