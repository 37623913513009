import React, { useCallback, useRef, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { format } from 'date-fns';
import * as yup from 'yup'
import {
    Avatar,
    Badge,
    Box,
    Button,
    MenuItem,
    styled,
    TextField
} from '@mui/material'
import authApiService from '../../../services/authApiService';
import EyeToggleButton from '../../../components/EyeToggleButton';
import userApiService from '../../../services/userApiService';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import fileApiRoutes from '../../../services/fileApiRoutes';
import { Chart } from 'primereact/chart';
import commentApiService from '../../../services/commentApiService';
import { useTranslation, Trans } from 'react-i18next';

const UserComment = () => {
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const [user, setUser] = useState({})
    const [rating, setRating] = useState(0)
    const [showStat, setShowStat] = useState(false)
    const [receivedComments, setReceivedComments] = useState([]);
    const [sendComments, setSendComments] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            getReceivedCommentByUserId(user?.id);
            getSentCommentByUserId(user?.id);
        }
    }, [])


    const getReceivedCommentByUserId = async (userId) => {
        setLoading(true);
        let response = await commentApiService.getReceivedCommentByUserId(userId);
        setReceivedComments(response);
        setLoading(false);
    }

    const getSentCommentByUserId = async (userId) => {
        setLoading(true);
        let response = await commentApiService.getSendCommentByUserId(userId);
        setSendComments(response);
        setLoading(false);
    }

    const showSuccessMessage = ({ title, message }) => {
        toast.current.show({ severity: 'success', summary: title, detail: message, life: 3000 });
    }

    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
    }

    const fileInputRef = useRef(null);

    return (
        <div className=''>
            <h3 className='text mt-0 pt-0'>{t('userCommentPage.title')} <br />
                <div className='text-xl font-medium mt-3'>{t('userCommentPage.sendCommentTile')}</div>
            </h3>
            <div>
                {
                    sendComments.length > 0 ? (
                        <div>

                        </div>
                    ) : (
                        <p>{t('userCommentPage.noSendComment')}</p>
                    )
                }
            </div>

            
            <h3 className='text mt-4 pt-0'>
                <div className='text-xl font-medium mt-3'>{t('userCommentPage.receivedCommentTile')}</div>
            </h3>
            <div>
                {
                    receivedComments.length > 0 ? (
                        <div>
                            
                        </div>
                    ) : (
                        <p>{t('userCommentPage.noReceivedComment')}</p>
                    )
                }
            </div>
        </div>
    );
};

export default UserComment;
