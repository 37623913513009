import React, { useState } from 'react';
import './FAQ.css';
import Header from './components/header/Header';
import Footer from '../../components/footer/Footer';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useTranslation, Trans } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation();

    const [questions, setQuestions] = useState([
        {
            title: 'QUESTION 1 ?',
            message: "Bonjour",
            theme: "THEME 1"
        },
        {
            title: 'QUESTION 2 ?',
            message: "Bonjour",
            theme: "THEME 1"
        },
        {
            title: 'QUESTION 3 ?',
            message: "Bonjour",
            theme: "THEME 1"
        },
        {
            title: 'QUESTION 1 ?',
            message: "Bonjour",
            theme: "THEME 2"
        },
        {
            title: 'QUESTION 2 ?',
            message: "Bonjour",
            theme: "THEME 2"
        },
        {
            title: 'QUESTION 3 ?',
            message: "Bonjour",
            theme: "THEME 2"
        },
        {
            title: 'QUESTION 1 ?',
            message: "Bonjour",
            theme: "THEME 3"
        },
        {
            title: 'QUESTION 2 ?',
            message: "Bonjour",
            theme: "THEME 3"
        },
        {
            title: 'QUESTION 3 ?',
            message: "Bonjour",
            theme: "THEME 3"
        },
    ]);

    const [themes, setThemes] = useState([
        'THEME 1', 'THEME 2', 'THEME 3'
    ]);


    return (
        <div className="faq-page">
            <Header />
            <div className="faq p-4 md:p-6 max-width-container m-auto">
                <did className="max-width-container m-auto">
                    <div class="select-box">
                        <span>{t("faqPage.main.select")}</span>
                    </div>

                    <div className="faq-themes">
                        {themes.map((theme, index) => (
                            <div key={index} className="faq-theme">
                                <h2>{theme} :</h2>

                                <Accordion activeIndex={0}>
                                    {questions.filter(question => question.theme == theme).map((question, idx) => (
                                        <AccordionTab header={question.title}>
                                            <p className="m-0">
                                                {question.message}
                                            </p>
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                                <div className='py-3'>
                                    <div className='line'></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </did>
            </div>
            <Footer />
        </div>
    );
}

export default FAQ;
