// src/components/Footer.js
import { Box } from '@mui/material';
import React from 'react';

const MessageCard = ({
    time,
    message,
    bgColor,
    marginLeft
}) => {
    return (
        <Box className="w-9 md:w-6 mb-3" style={{
            marginLeft: marginLeft,
        }}>
            <Box
                className="border-round-sm message-item-card shadow-1"
                style={{
                    marginBottom: '.4rem',
                    background: bgColor
                }}
                sx={{
                    p: 2
                }}
            >
                {typeof message === 'string' ? (
                    <p className='m-0 p-0'>
                        {message}
                    </p>
                ) :
                    message.map(item => {
                        return (
                            <p className='m-0 p-0' >
                                {item}
                            </p>
                        )
                    })
                }
            </Box>
            <div className='text-right w-full text-sm text-gray-400'>
                {time}
            </div>
        </Box>
    );
}

export default MessageCard;
