import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Avatar } from 'primereact/avatar';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import commentApiService from '../../services/commentApiService';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useTranslation, Trans } from 'react-i18next';
import { ListSubheader, MenuItem, Select, TextField } from '@mui/material';

const LayoutSidebar = ({
    className,
    language,
    openAnnounceModal,
    changeLanguage,
    languages
}) => {
    const [selectedLanguage, setSelectedLanguage] = useState(language ?? "fr")

    const profilMenuRef = useRef(null);
    const [logUser, setLogUser] = useState({
        id: ""
    });
    const [rating, setRating] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();

    let profilMenuItems = [
        {
            label: t("sidebar.menu.profil.view"),
            icon: 'pi pi-user',
            command: () => {
                navigate('/profil')
            }
        },
        {
            label: t("sidebar.menu.profil.update"),
            icon: 'pi pi-user-edit',
            command: () => {
                navigate('/profil/update')
            }
        },
        {
            label: t("sidebar.menu.logout"),
            icon: 'pi pi-sign-out',
            command: () => {
                setLogUser({})
                localStorage.clear()
                sessionStorage.clear()
                navigate('/')
            }
        }
    ];

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setLogUser(user);
            getUserRating(user);
        }
    }, [])

    const getUserRating = async (userId) => {
        let response = await commentApiService.getRatingById(userId);
        setRating(response);
    }

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.clear();
        navigate('/');
    }

    return (
        <div className={className + ' sidebar'}>
            <div className='flex justify-content-center flex-column align-items-center text-center'>
                <Avatar image={logUser?.avatar ? logUser?.avatar : "/assets/images/001-man.svg"} className="w-6rem h-6rem mb-4" size="xlarge" shape="circle" />
                {/* <Rating value={rating} readOnly cancel={false} /> */}
            </div>

            <ul className='mt-4 list-none p-0'>
                <li className='text'>
                    <Link to="/" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname == '/' > -1 ? 'active' : ''
                    )}>{t('sidebar.menu.home')}</Link>
                </li>
                <li className='text'>
                    <span className="text-lg text no-underline border-none link" onClick={() => {
                        if (openAnnounceModal) {
                            openAnnounceModal();
                        }
                    }
                    }>{t('sidebar.menu.placeAnOffer')}</span>
                </li>
                <li className='text'>
                    <Link to="/profil-message" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/profil-message') > -1 ? 'active' : ''
                    )}>{t('sidebar.menu.message')}</Link>
                </li>
                <li className="text">
                    <Select
                        // Disables auto focus on MenuItems and allows TextField to be in focus
                        MenuProps={{ autoFocus: false }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        size='small'
                        labelId="search-select-label-for-currencyTo"
                        id="search-select-for-currencyTo"
                        value={selectedLanguage}
                        label="Options"
                        onChange={(e) => {
                            if (changeLanguage) {
                                setSelectedLanguage(e.target.value)
                                changeLanguage(e.target.value)
                            }
                        }
                        }
                    >
                        <ListSubheader>
                            <TextField
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder={t("global.changeLanguage")}
                                fullWidth
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                        {languages.map((option, i) => (
                            <MenuItem key={i} value={option.code}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </li>

                <li className='text'>
                    <Link to="/profil" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/profil/announces') > -1 ? 'active' : ''
                    )}>{t('sidebar.menu.myAnnounces')}</Link>
                </li>
                {/* <li className='text'>
                    <Link to="/faq" className={"text-lg text no-underline border-none link " + (
                        window.location.pathname.indexOf('/faq') > -1 ? 'active' : ''
                    )}>{t('sidebar.menu.help')}</Link>
                </li> */}
            </ul>
        </div>
    );
}

export default LayoutSidebar;
