import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./pages/home/Home";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-blue/theme.css";
import './App.css';
import FAQ from "./pages/faq/FAQ";
import { unstable_createRoot } from 'react-dom';
import ProfilMessage from "./pages/profil-message/ProfilMessage";
import Profil from "./pages/profil/Profil";
import React, { Fragment, useEffect, useState, useRef } from 'react'
import MessageDetail from "./pages/message-detail/MessageDetail";
import SearchAnnouncePage from "./pages/search-announce-page/SearchAnnouncePage";
import { es, fr } from 'date-fns/locale'
import { setDefaultOptions } from "date-fns/setDefaultOptions";
import NotFound from "./pages/notFound/NotFound";
import AccountForm from "./pages/profil/components/AccountForm";
import ProfilComponent from "./pages/profil/components/ProfilComponent";
import UserPoint from "./pages/profil/components/UserPoint";
import UserComment from "./pages/profil/components/UserComment";
import MessageAnnouncement from "./pages/profil/components/MessageAnnouncement";
import { url } from '../src/services/constants'
import './i18n';
import TermsAndConditions from "./pages/terms-conditions/TermsAndConditions";
import { Toast } from 'primereact/toast';

setDefaultOptions({ locale: fr })

function App() {
  const toast = useRef(null);

  const value = {
    zIndex: {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,     // overlay menus
      tooltip: 1100,   // tooltip
      toast: 2400     // toast
    },
    autoZIndex: true,
  };
  return (
    <Fragment>
      <head>
        <meta charSet='utf-8' />
        <title>SoluChange</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <title>Échange de Devises et Envoi d'Argent - Service Rapide et Fiable</title>
        <meta name="description" content="Découvrez notre plateforme d'échange de devises et d'envoi d'argent, rapide, sécurisée, et aux taux compétitifs. Envoyez et échangez des devises en toute confiance." />

        <meta name="keywords" content="échange de devises, envoi d'argent, transferts d'argent, taux de change, transfert international, conversion de devises, transfert sécurisé" />
        <meta name="author" content="Pategou Consulting & Co" />


        {/* <!-- Balises additionnelles pour le SEO --> */}
        <meta name="robots" content="index, follow" />
        <meta name="og:title" content="Échange de Devises et Envoi d'Argent - Service Rapide et Fiable" />
        <meta name="og:description" content="Découvrez notre plateforme d'échange de devises et d'envoi d'argent, rapide, sécurisée, et aux taux compétitifs. Envoyez et échangez des devises en toute confiance." />
        <meta name="og:image" content="https://soluchange.com/assets/icons/solu.png" />

        {/* <!-- URL de l'image à partager sur les réseaux sociaux --> */}
        <meta name="og:url" content="https://soluchange.com" />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="SoluChange" />

        <meta name="twitter:card" content="https://soluchange.com/assets/icons/solu.png" />
        <meta name="twitter:title" content="Échange de Devises et Envoi d'Argent - Service Rapide et Fiable" />
        <meta name="twitter:description" content="Découvrez notre plateforme d'échange de devises et d'envoi d'argent, rapide, sécurisée, et aux taux compétitifs. Envoyez et échangez des devises en toute confiance." />
        <meta name="twitter:image" content="https://soluchange.com/assets/icons/solu.png" />
        <meta name="twitter:site" content="@ChangeSolu33960" />
        <meta name="twitter:creator" content="@ChangeSolu33960" />

        <meta name="theme-color" content="#ffffff" />
        <link rel="manifest" href="/manifest.json" />

        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="apple-touch-icon" sizes="180x180" href="https://soluchange.com/assets/icons/solu.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://soluchange.com/assets/icons/solu.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://soluchange.com/assets/icons/solu.png" />

        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        <script src='https://ajax.googleapis.com/ajax/libs/jquery/1.8.3/jquery.min.js' />
        <script src='https://maps.googleapis.com/maps/api/js?key=AIzaSyBewJ2x5COtaqlSdrt8DdKObNP_rRWCEEE&libraries=places,drawing,geometry' />
      </head>
      <PrimeReactProvider value={value}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="welcome/:email" element={<Home />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="profil-message" element={<ProfilMessage />} />
              <Route path="terms" element={<TermsAndConditions />} />
              <Route path="/profil" element={<Profil />}>
                <Route path="" element={<ProfilComponent />} />
                <Route path="announces" element={<ProfilComponent />} />
                <Route path="update" element={<AccountForm />} />
                <Route path="comments" element={<UserComment />} />
                <Route path="announce/:announceId" element={<MessageAnnouncement />} />
                <Route path="points" element={<UserPoint />} />
              </Route>
              <Route path="search" element={<SearchAnnouncePage />} />
              <Route path="/message-detail/announce/:announceId/sender/:senderId/owner/:ownerOfAnnouncementId" element={<MessageDetail />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </PrimeReactProvider>

    </Fragment>
  );
}

export default App;


let root = null;

function renderApp() {
  if (!root) {
    root = ReactDOM.createRoot(document.getElementById('root'));
  }
  root.render(<App />);
}

renderApp();

