import Footer from "../../components/footer/Footer";
import './Home.css';
import { TabMenu } from 'primereact/tabmenu';
import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import Map from "./components/map/Map";
import { Rating } from 'primereact/rating';
import { Carousel } from 'primereact/carousel';
import AnnouncementCard from "./components/AnnouncementCard/AnnounceCard";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import userApiService from '../../services/userApiService'
import { Toast } from 'primereact/toast';
import { useNavigate, useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import OtpVerificationModal from "../../components/auth-modal/OtpVerificationCodeModal";
const Home = () => {
  const { email } = useParams();

  const { showSuccessMessage, showErrorMessage } = useOutletContext();

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  const [orientation, setOrientation] = useState("horizontal")
  const [testimonials, setTestimonials] = useState([
    {
      rating: 0,
      title: "Excellent services",
      postAt: "Jan 17",
      message: "Excellent services, good rates and fast",
      sendername: "Suzanne"
    },
    {
      rating: 5,
      title: "Excellent services",
      message: "Excellent services, good rates and fast",
      sendername: "Suzanne"
    },
    {
      rating: 3,
      title: "Excellent services",
      message: "Excellent services, good rates and fast",
      sendername: "Suzanne"
    },
    {
      rating: 3,
      title: "Excellent services",
      message: "Excellent services, good rates and fast",
      sendername: "Suzanne"
    },
    {
      rating: 3,
      title: "Excellent services",
      message: "Excellent services, good rates and fast",
      sendername: "Suzanne"
    },
  ]);

  const stepperRef = useRef(null);
  const navigate = useNavigate();

  const [size, setSize] = useState([0, 0]);

  const getUserByEmail = async (email) => {
    let user = await userApiService.getUserByEmail(email);
    if (user?.error) {
      showErrorMessage({
        title: t('errors.userRecoveryFailed'), message: user.error
      });
    } else {
      localStorage.setItem('user', JSON.stringify(user));
      showSuccessMessage({
        title: t('login.succeed.title'), message: t('login.succeed.message')
      });
      navigate('/');
      window.location.reload();
    }
  }

  const { t } = useTranslation();

  useEffect(() => {
    if (email) {
      getUserByEmail(email)
    }
  }, [email])

  function onWindowSizeChange() {
    setSize([window.innerWidth, window.innerHeight]);

    if (window.innerWidth < 587) {
      // setOrientation("vertical")
    } else {
      // setOrientation("horizontal")
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', onWindowSizeChange);
    onWindowSizeChange();
    return () => window.removeEventListener('resize', onWindowSizeChange);
  }, []);

  const testimonialTemplate = (testimonial) => {
    return (
      <div className="py-3 md:py-5 px-1 md:px-2">
        <div className="testimonial-card p-2 md:p-3">
          <div className="flex align-items-center wrap justify-content-between">
            <Rating value={testimonial.rating} readOnly cancel={false} />
            <span>{testimonial.postAt}</span>
          </div>
          <div className="">
            <h4 className="text-primary font-semibold">{testimonial.title}</h4>
            <p className="text-sm text-primary font-medium" >{testimonial.message}</p>
            <p className="text-sm font-medium" >{testimonial.sendername}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="home-page z-5 overflow-x-hidden">

      <header className="header">
        <div className="toolbar flex relative">
          <div className='absolute z-1  h-full w-full overflow-hidden'>
            <div className='h-full w-full relative z-5'>
              <div className='image-container z-1 h-full w-full'>
                <div className='image-content h-full w-full'>

                </div>
              </div>
            </div>
          </div>

          <div className="text-content  z-5 relative w-full">
            <div className="py-4 toolbar-content grid max-width-container m-auto">
              <div className="col-12 md:col-6 flex flex-column align-items-center justify-content-center md:justify-content-start py-6 md:pb-6 md:pt-0 px-4 md:px-8">
                <div className="">
                  <h1 className="text-3xl text-white text-left font-medium mb-0 mt-0"><Trans i18nKey="homePage.toolbarTitle">ENVOYEZ DE L'ARGENT ET ECHANGEZ <span >GRATUITEMENT</span> VOS DEVISES ENTRE PARTICULIERS PARTOUT DANS LE MONDE</Trans></h1>
                  {/* <p className="hidden text-xl text-left mb-6 font-semibold gap-1 flex flex-column"><Trans i18nKey="homePage.toolbarDescription" ><span className="p-1">Sans commission</span><span className="p-1">Partout dans le monde</span><span className="p-1">A toute heure</span></Trans></p> */}
                </div>
              </div>
              <div className="col-12 md:col-6 mx-auto  px-4 md:pl-0 md:pr-6 pb-6 md:py-0 flex align-items-start justify-content-center">
                <div className="w-full">
                  <AnnouncementCard
                    showSuccessMessage={({ title, message }) => {
                      showSuccessMessage({
                        title: title, message: message
                      });
                    }}
                    showErrorMessage={({ title, message }) => {
                      showErrorMessage({
                        title: title, message: message
                      });
                    }}
                    textFieldClass={'text-white'} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </header>
      <div className="features py-2">
        <div className="grid relative max-width-container mx-auto ">
          <div className="col-12 md:col-6 px-4 pt-4 pb-3 md:p-6">
            <img src="/assets/images/laptops.png" className="w-full" alt="Devices" />
          </div>
          <div className="col-12 md:col-6 pt-3 px-6 pb-6 md:p-6">
            <h1 className="mb-6 mt-0 font-normal uppercase text-500">
              {t('homePage.goal.title')}
            </h1>
            <p className="m-0 text-primary text-lg line-height-3"> {t('homePage.goal.message1')} </p>
            <p className="m-0 text-primary text-lg line-height-3"><Trans i18nKey="homePage.goal.message2">Elle permet la mise en relation des particuliers dans le but d'envoyer de l'argent et d'échanger des devises <span className="text-secondary">gratuitement</span> et sans intermediaire.</Trans></p>
          </div>
        </div>
      </div>
      <div className="steps-container p-4 md:p-6 relative overflow-x-hidden">
        <div className="steps-content max-width-container mx-auto">
          <h2 className="mt-0 mb-3">{t('homePage.howItWork.title')}</h2>
          <p className="mt-0 mb-5">{t('homePage.howItWork.subtitle')}</p>

          <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }} orientation={orientation}>
            <StepperPanel header="">
              <div className="flex flex-column  step-panel-container">
                <div className="border-2 border-dashed mx-auto surface-border surface-ground flex justify-content-center align-items-center border-circle" style={{
                  width: '6em', height: '6em'
                }} >
                  <img src="/assets/icons/exchange.png" style={{
                    width: '70%', height: '70%'
                  }} />
                </div>
                <div>
                  <h3 className="font-medium text-white">{t('homePage.stepper.step1.title')}</h3>
                  <p className="text-white line-height-3 m-0">{t('homePage.stepper.step1.message')}</p>
                </div>
                {/* <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Envoyer de l'argent ou échanger des devises</div> */}
              </div>
              <div className="flex pt-4 justify-content-end">
                <Button label={t('global.next')} icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
              </div>
            </StepperPanel>
            <StepperPanel header="">
              <div className="flex flex-column  step-panel-container">
                <div className="border-2 border-dashed mx-auto surface-border surface-ground flex justify-content-center align-items-center border-circle" style={{
                  width: '6em', height: '6em'
                }} >
                  <img src="/assets/icons/form.png" style={{
                    width: '70%', height: '70%', objectFit: 'contain'
                  }} />
                </div>
                <div>
                  <h3 className="font-medium text-white">{t('homePage.stepper.step2.title')}</h3>
                  <p className="text-white line-height-3 m-0">{t('homePage.stepper.step2.message')}</p>
                </div>
              </div>
              <div className="flex pt-4 justify-content-between">
                <Button label={t('global.previous')} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                <Button label={t('global.next')} icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
              </div>
            </StepperPanel>
            <StepperPanel header="">
              <div className="flex flex-column  step-panel-container">
                <div className="border-2 border-dashed mx-auto surface-border surface-ground flex justify-content-center align-items-center border-circle" style={{
                  width: '6em', height: '6em'
                }} >
                  <img src="/assets/icons/partnership.png" style={{
                    width: '70%', height: '70%'
                  }} />
                </div>
                <div>
                  <h3 className="font-medium text-white">{t('homePage.stepper.step3.title')}</h3>
                  <p className="text-white line-height-3 m-0">{t('homePage.stepper.step3.message')}</p>
                </div>
              </div>
              <div className="flex pt-4 justify-content-between">
                <Button label={t('global.previous')} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                <Button label={t('global.next')} icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
              </div>
            </StepperPanel>
            <StepperPanel header="">
              <div className="flex flex-column  step-panel-container">
                <div className="border-2 border-dashed mx-auto surface-border surface-ground flex justify-content-center align-items-center border-circle" style={{
                  width: '6em', height: '6em'
                }} >
                  <img src="/assets/icons/contact.png" style={{
                    width: '70%', height: '70%'
                  }} />
                </div>
                <div>
                  <h3 className="font-medium text-white">{t('homePage.stepper.step4.title')}</h3>
                  <p className="text-white line-height-3 m-0">{t('homePage.stepper.step4.message')}</p>
                </div>
              </div>
              <div className="flex pt-4 justify-content-start">
                <Button label={t('global.previous')} severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
              </div>
            </StepperPanel>
          </Stepper>
        </div>
      </div>
      {/* <div className="testimonials-container p-4 md:p-6">
        <div className="max-width-container mx-auto">
          <h1 className="mb-2 text-center mt-0 font-normal text-primary uppercase text-500">
            SEE WHAT OTHERS ARE THINKING ABOUT US
          </h1>
          <div>
            <Carousel value={testimonials} numVisible={5} numScroll={5} responsiveOptions={responsiveOptions} className="custom-carousel" circular
              autoplayInterval={10000} itemTemplate={testimonialTemplate} />
          </div>
        </div>
      </div> */}
      {/* <Map /> */}
      <Footer />


      {/* <OtpVerificationModal onClose={() => {}} onSubmit={() => {}} open={true} key={'auth-code'}/> */}
    </div>
  );
};

export default Home;