import React, { useCallback, useRef, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { format } from 'date-fns';
import * as yup from 'yup'
import {
    Avatar,
    Badge,
    Box,
    Button,
    MenuItem,
    styled,
    TextField
} from '@mui/material'
import authApiService from '../../../services/authApiService';
import EyeToggleButton from '../../../components/EyeToggleButton';
import userApiService from '../../../services/userApiService';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import fileApiRoutes from '../../../services/fileApiRoutes';
import { Chart } from 'primereact/chart';
import commentApiService from '../../../services/commentApiService';
import { useTranslation, Trans } from 'react-i18next';

const UserPoint = () => {
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const [user, setUser] = useState({})
    const [rating, setRating] = useState(0)
    const [showStat, setShowStat] = useState(false)
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            getUseById(user?.id);
            getRatingStatByUserId(user?.id);
        }
    }, [])

    const getUseById = async (userId) => {
        setLoading(true);
        let userResponse = await userApiService.getUserById(userId);
        if (userResponse && userResponse?.id) {
            setUser(userResponse);
        }
        setLoading(false);
    }

    const getRatingStatByUserId = async (userId) => {
        setLoading(true);
        let response = await commentApiService.getRatingStatById(userId);
        setShowStat(response.labels.length > 0)
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: response.labels,
            datasets: [
                {
                    data: response.data,
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
        setLoading(false);
    }

    const showSuccessMessage = ({ title, message }) => {
        toast.current.show({ severity: 'success', summary: title, detail: message, life: 3000 });
    }

    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
    }

    const fileInputRef = useRef(null);

    return (
        <div className=''>
            <h3 className='text mt-0 pt-0'>{t('userPointPage.title')}</h3>
            <p className='line-height-3'>
                {t('userPointPage.description')}
            </p>
            {
                showStat ? (
                    <div className='w-full text-center flex justify-content-center align-items-center'>
                        <Chart type="pie" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                    </div>
                ) : ''
            }

            <h4>{t('userPointPage.summarize')} : {rating}</h4>
        </div>
    );
};

export default UserPoint;
