import React, { useRef, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { format } from 'date-fns';
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/material.css'
// import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import 'react-phone-input-2/lib/material.css'
import * as yup from 'yup'
import {
    Avatar,
    Badge,
    Box,
    Button,
    MenuItem,
    styled,
    TextField
} from '@mui/material'
import userApiService from '../../../services/userApiService';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import fileApiRoutes from '../../../services/fileApiRoutes';
import { Toast } from 'primereact/toast';
import { useTranslation, Trans } from 'react-i18next';

const AccountForm = () => {
    const { t } = useTranslation();
    const [date, setDate] = useState(new Date());
    const [email, setEmail] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [birthday, setBirthday] = useState(null);
    const [firstname, setFirstname] = useState(null);
    const [birthmonth, setBirthmonth] = useState(null);
    const [birthyear, setBirthyear] = useState(null);
    const [sexe, setSexe] = useState(null);
    const [phone, setPhone] = useState(null);
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(true)
    const toast = useRef(null);
    const [user, setUser] = useState({})
    const [avatar, setAvatar] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [initialValues, setInitialValues] = useState({
        lastname: '',
        firstname: '',
        email: '',
        sexe: '',
        birthyear: 0,
        birthday: 0,
        birthmonth: 0,
        phone: '',
    })

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setPreview(URL.createObjectURL(file));
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const signupSchema = yup.object().shape({
        lastname: yup.string().required(t("components.auth.requirement.lastnameRequired")),
        firstname: yup.string().required(t("components.auth.requirement.firstnameRequired")),
        email: yup.string().email(t("components.auth.requirement.emailRequired")),
        sexe: yup.string().required(t("components.auth.requirement.sexRequired")),
        phone: yup.string(),
        birthmonth: yup.number()
            .required(t("components.auth.requirement.monthRequired"))
            .positive()
            .integer()
            .min(1, t("components.auth.requirement.minValueValidation"))
            .max(12, t("components.auth.requirement.monthValidation"))
        ,
        birthyear: yup.number()
            .required(t("components.auth.requirement.yearRequired"))
            .positive()
            .integer()
            .max((date.getUTCFullYear() - 18), t("components.auth.requirement.dateMinValueValidation"))
        ,
        birthday: yup.number()
            .required(t("components.auth.requirement.dayRequired"))
            .positive()
            .integer()
            .min(1, t("components.auth.requirement.minValueValidation"))
            .max(31, t("components.auth.requirement.dayValidation"))
        ,
        password: yup.string()
            .required(t("components.auth.requirement.passwordRequired"))
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                t("components.auth.requirement.passwordRequiredRegex")
            ),
        re_password: yup
            .string()
            .oneOf(
                [yup.ref('password'), null],
                t("components.auth.requirement.passwordNotSame")
            )
            .required(t("components.auth.requirement.reEnterPassword")),
        agreement: yup
            .bool()
            .test(
                'accord',
                'Vous devez accepter nos termes et conditions !',
                value => value === true
            )
            .required(t("components.auth.requirement.acceptTerm"))
    })

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user)
            getUseById(user?.id);
        }
    }, [])

    const getUseById = async (userId) => {
        setLoading(true);
        let userResponse = await userApiService.getUserById(userId);
        if (userResponse && userResponse?.id) {
            setInitialValues({
                ...userResponse,
                birthday: format(userResponse.birthday, 'dd'),
                birthmonth: format(userResponse.birthday, 'MM'),
                birthyear: format(userResponse.birthday, 'yyyy')
            })
            setUser(userResponse);
            setLastname(userResponse.lastname)
            setSexe(userResponse.sexe)
            setAvatar(userResponse?.avatar ? userResponse?.avatar : "/assets/images/001-man.svg")
            setFirstname(userResponse.firstname)
            setPhone(userResponse.phone)
            setEmail(userResponse.email)
            setBirthday(format(userResponse.birthday, 'dd'))
            setBirthmonth(format(userResponse.birthday, 'MM'))
            setBirthyear(format(userResponse.birthday, 'yyyy'))
        }
        setLoading(false);
    }

    const handleUpdateSubmit = async (event) => {
        // event?.preventDefault();
        // Ton code de soumission de formulaire ici
        setLoader(true);
        try {
            let url = "";
            if (selectedFile) {
                let response = await fileApiRoutes.uploadFile(selectedFile);
                if (response?.error) {
                    url = "";
                } else {
                    url = response.url;
                }
            };

            // Votre logique de soumission
            let response = await userApiService.updateUser(user?.id,
                {
                    email,
                    phone,
                    sexe,
                    avatar: url,
                    lastname,
                    firstname,
                    "birthday": new Date(birthyear, birthmonth - 1, birthday)
                }
            );

            if (response?.id) {
                showSuccessMessage({
                    title: t('accountPage.succeed.title'), message: t('accountPage.succeed.message')
                });
            } else {
                showErrorMessage({
                    title: t('errors.updateAccountLost'), message: response.error
                });
            }
        } catch (error) {
            console.error(t("errors.loginError"), error);
        }
        setLoader(false);
    };

    const showSuccessMessage = ({ title, message }) => {
        toast.current.show({ severity: 'success', summary: title, detail: message, life: 3000 });
    }

    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
    }

    const fileInputRef = useRef(null);

    return loading ? (
        <h3>{t('global.inProgress')}</h3>
    ) : (
        <div className=''>
            <Toast ref={toast} />
            <h3 className='text mt-0 pt-0'>{t('accountPage.title')}</h3>
            <Formik
                initialValues={initialValues}
                validationSchema={signupSchema}
                onSubmit={handleUpdateSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="formgrid grid mt-2">
                            <div className="field col md:col-12 text-center">

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <PhotoCameraIcon onClick={handleButtonClick} className='cursor-pointer w-2rem h-2rem' />
                                    }
                                >
                                    <Avatar alt="Avatar" src={preview ? preview : avatar} className='w-8rem h-8rem' />
                                </Badge>
                            </div>
                        </div>
                        <div className="formgrid grid mt-2">
                            <div className="field col md:col-12">
                                <TextField
                                    fullWidth
                                    size='small'
                                    id='email'
                                    name='email'
                                    label={t("components.auth.email")}
                                    type='email'
                                    value={values.email}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setEmail(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    error={!!touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col md:col-12">
                                <PhoneInput
                                    inputStyle={{ width: '100%' }}
                                    size='small'
                                    id='phone'
                                    name='phone'
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    onFocus={(e) => {
                                    }}
                                    isValid={(value, country) => {
                                        // if (value == country.dialCode) {
                                        //     return "Numero de téléphone requis";
                                        // }
                                        if (value.match(/12345/)) {
                                            return 'Invalid value: ' + value + ', ' + country.name;
                                        } else if (value.match(/1234/)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    preferredCountries={['cm']}
                                    countryCodeEditable={false}
                                    mb={1.5}
                                    variant='outlined'
                                    country={'cm'}
                                    value={values.phone}
                                    onBlur={handleBlur}
                                    error={!!touched.phone && !!errors.phone}
                                    helperText={touched.phone && errors.phone}
                                    localization={fr}
                                    onChange={phone => setPhone(phone)}
                                    enableSearch={true}
                                    enableTerritories={true}
                                    searchPlaceholder={t("global.search")}
                                    specialLabel={t("components.auth.phone")}
                                    searchNotFound={t("errors.noCorrespondance")}
                                />

                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col md:col-12">
                                <TextField
                                    fullWidth
                                    size='small'
                                    id='firstname'
                                    name='firstname'
                                    label={t("components.auth.firstname")}
                                    type='text'
                                    value={values.firstname}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setFirstname(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    error={!!touched.firstname && !!errors.firstname}
                                    helperText={touched.firstname && errors.firstname}
                                />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col md:col-12">
                                <TextField
                                    fullWidth
                                    size='small'
                                    id='lastname'
                                    name='lastname'
                                    label={t("components.auth.lastname")}
                                    type='text'
                                    value={values.lastname}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setLastname(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    error={!!touched.lastname && !!errors.lastname}
                                    helperText={touched.lastname && errors.lastname}
                                />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col md:col-12">
                                <TextField
                                    fullWidth
                                    size='small'
                                    select
                                    id='sexe'
                                    name='sexe'
                                    label={t("components.auth.sexe.label")}
                                    value={values.sexe}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSexe(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    error={!!touched.sexe && !!errors.sexe}
                                    helperText={touched.sexe && errors.sexe}
                                >
                                    <MenuItem value='MASCULIN'>{t("components.auth.sexe.masculin")}</MenuItem>
                                    <MenuItem value='FEMININ'>{t("components.auth.sexe.feminin")}</MenuItem>
                                </TextField>
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col md:col-12">
                                <div className="flex gap-2 justify-content-between align-items-start">
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id='birthday'
                                        name='birthday'
                                        InputProps={{ inputProps: { min: 1, max: 31 } }}
                                        label={t("components.auth.day")}
                                        type='number'
                                        value={values.birthday}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setBirthday(e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        error={!!touched.birthday && !!errors.birthday}
                                        helperText={touched.birthday && errors.birthday}
                                    />
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id='birthmonth'
                                        name='birthmonth'
                                        label={t("components.auth.month")}
                                        type='number'
                                        InputProps={{ inputProps: { min: 1, max: 12 } }}
                                        value={values.birthmonth}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setBirthmonth(e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        error={!!touched.birthmonth && !!errors.birthmonth}
                                        helperText={touched.birthmonth && errors.birthmonth}
                                    />
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id='birthyear'
                                        name='birthyear'
                                        label={t("components.auth.year")}
                                        type='number'
                                        InputProps={{
                                            inputProps: {
                                                max: (date.getUTCFullYear() - 18)
                                            }
                                        }}
                                        value={values.birthyear}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setBirthyear(e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        error={!!touched.birthyear && !!errors.birthyear}
                                        helperText={touched.birthyear && errors.birthyear}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col md:col-12 mt-2">
                                <Button
                                    type="submit"
                                    variant='contained'
                                    color='error'
                                    className='w-full py-2'
                                    disabled={loader}
                                >
                                    <span style={{ marginRight: '5px' }}>{t('accountPage.update')}</span>
                                    {loader ? <i className="pi pi-spin pi-spinner" ></i> : <></>}
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default AccountForm;

const ContentWrapper = styled(Box)(({ theme }) => ({
    '& .carousel:hover': {
        cursor: 'pointer',
        '& .carousel__back-button': {
            opacity: 1,
            left: 10
        },
        '& .carousel__next-button': {
            opacity: 1,
            right: 10
        }
    },
    '& .carousel__next-button, & .carousel__back-button': {
        opacity: 0,
        boxShadow: 'none',
        transition: 'all 0.3s',
        background: 'transparent',
        color: theme.palette.primary.main,
        ':disabled': {
            color: theme.palette.grey[500]
        },
        ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    '& .carousel__back-button': {
        left: 0
    },
    '& .carousel__next-button': {
        right: 0
    }
})) // =====================================================
