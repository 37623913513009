// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { useTranslation, Trans } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer class="site-footer footer px-4 md:px-6">
      <div class="footer-content w-full md:w-7">
        <div class="social-links text-center">
          <h3>{t('components.footer.contactUs')}</h3>
          <div className='flex d-flex w-full justify-content-center align-items-center'>
          <a href="https://www.facebook.com/share/XMMbr9Ct1cu319s2/?mibextid=qi2Omg" class="social-icon"><i class="pi pi-facebook"></i></a>
          <a href="https://www.instagram.com/soluchange9?igsh=bXR1andjbG1wOThj" class="social-icon"><i class="pi pi-instagram"></i></a>
          <a href="https://x.com/ChangeSolu33960" class="social-icon"><i class="pi pi-twitter"></i></a>
          <a href="mailto:contact@soluchange.com" class="social-icon"><i class="pi pi-envelope"></i></a>
          </div>
        </div>
        <div class="quick-links text-center">
          <h3>{t('components.footer.fastLinks')}</h3>
          <ul>
            <li><a href="#">{t('components.footer.home')}</a></li>
            <li><a href="terms">{t('components.footer.condition')}</a></li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; {t('components.footer.licence')}</p>
      </div>
    </footer>
  );
}

export default Footer;
