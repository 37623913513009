import React from 'react';
import './TermsAndConditions.css'; // Vous devrez créer ce fichier CSS pour le style
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='terms-page'>
                <div className='header'>
                    <div className='toolbar px-4 md:px-6'>
                        <div className='toolbar-content max-width-container mx-auto'>
                            <div className='flex h-full justify-content-center align-items-center py-5'>
                                <h1 className='text-6xl text-center m-0'>{t('termsPage.title')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="terms-and-conditions">
                <p>{t('termsPage.terms-and-conditions.last-updated')}</p>
                <p>{t('termsPage.terms-and-conditions.welcome-message')}</p>

                <h2>{t('termsPage.terms-and-conditions.acceptance-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.acceptance-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.description-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.description-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.eligibility-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.eligibility-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.user-responsibility-title')}</h2>
                <h3>{t('termsPage.terms-and-conditions.user-verification-title')}</h3>
                <p>{t('termsPage.terms-and-conditions.user-verification-description')}</p>

                <h3>{t('termsPage.terms-and-conditions.law-compliance-title')}</h3>
                <p>{t('termsPage.terms-and-conditions.law-compliance-description')}</p>

                <h3>{t('termsPage.terms-and-conditions.transactions-title')}</h3>
                <p>{t('termsPage.terms-and-conditions.transactions-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.liability-title')}</h2>
                <h3>{t('termsPage.terms-and-conditions.warranty-exclusion-title')}</h3>
                <p>{t('termsPage.terms-and-conditions.warranty-exclusion-description')}</p>

                <h3>{t('termsPage.terms-and-conditions.damages-title')}</h3>
                <p>{t('termsPage.terms-and-conditions.damages-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.data-protection-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.data-protection-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.modification-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.modification-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.termination-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.termination-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.governing-law-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.governing-law-description')}</p>

                <h2>{t('termsPage.terms-and-conditions.contact-title')}</h2>
                <p>{t('termsPage.terms-and-conditions.contact-description')}</p>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;