import React, { } from 'react'
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    styled
} from '@mui/material'
import AnnouncementCard from '../../pages/home/components/AnnouncementCard/AnnounceCard';
import { useTranslation, Trans } from 'react-i18next';

const AnnouncementModal = ({ onSubmit, open, onClose, opentLoginModal, openPublishedModalChoice, showSuccessMessage, showErrorMessage }) => {
    const { t } = useTranslation();
    return (
        <>
            <Dialog
                open={open}
                maxWidth={false}
                sx={{
                    zIndex: 100
                }}
            >
                <DialogTitle textAlign='center'>
                    <span style={{ fontWeight: 'bold' }}>{t('components.announceModal.addAnAnnounceTitle')}</span>
                </DialogTitle>
                <DialogContent
                    sx={{
                        maxWidth: 555,
                        width: '100%'
                    }}
                >
                    <ContentWrapper>
                        <AnnouncementCard closeDialog={() => { onClose(); }}
                            opentLoginModal={(offer) => {
                                if (opentLoginModal) {
                                    opentLoginModal(offer);
                                }
                            }}
                            openPublishedModalChoice={(offer) => {
                                if (openPublishedModalChoice) {
                                    openPublishedModalChoice(offer);
                                }
                            }}
                            showSuccessMessage={({ title, message }) => {
                                showSuccessMessage({
                                    title: title, message: message
                                });
                            }}
                            showErrorMessage={({ title, message }) => {
                                showErrorMessage({
                                    title: title, message: message
                                });
                            }}
                            mainStyle={'modal-card'}
                        />
                    </ContentWrapper>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 3,
                            right: 3,
                        }}
                        onClick={onClose}
                    >
                        <i className='pi  pi-times'></i>
                    </IconButton>
                </DialogContent>
            </Dialog >
        </>
    )
};

export default AnnouncementModal;

const ContentWrapper = styled(Box)(({ theme }) => ({
    '& .carousel:hover': {
        cursor: 'pointer',
        '& .carousel__back-button': {
            opacity: 1,
            left: 10
        },
        '& .carousel__next-button': {
            opacity: 1,
            right: 10
        }
    },
    '& .carousel__next-button, & .carousel__back-button': {
        opacity: 0,
        boxShadow: 'none',
        transition: 'all 0.3s',
        background: 'transparent',
        color: theme.palette.primary.main,
        ':disabled': {
            color: theme.palette.grey[500]
        },
        ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    },
    '& .carousel__back-button': {
        left: 0
    },
    '& .carousel__next-button': {
        right: 0
    }
})) // =====================================================
