import axios from 'axios'
import qs from 'qs'

import { url } from './constants'

const commentUrl = url + 'api/comment/'

const getRatingById = async (userId) => {
  let response = 0;
  // await axios
  //   .get(
  //     commentUrl + 'rating/user/' + userId,
  //     {
  //       withCredentials: true
  //     }
  //   )
  //   .then(res => {
  //     response = res.data
  //   })
  //   .catch(e => {
  //     response = 0;
  //   })
  return response
}

const getRatingStatById = async (userId) => {
  let response = {
    data: [],
    labels: []
  };
  // await axios
  //   .get(
  //     commentUrl + 'rating-stat/user/' + userId,
  //     {
  //       withCredentials: true
  //     }
  //   )
  //   .then(res => {
  //     response = res.data
  //   })
  //   .catch(e => {
  //     response = {
  //       data: [],
  //       labels: []
  //     };
  //   })
  return response
}

const getSendCommentByUserId = async (userId) => {
  let response = []
  // await axios
  //   .get(
  //     commentUrl + 'sendByUser/' + userId,
  //     {
  //       withCredentials: true
  //     }
  //   )
  //   .then(res => {
  //     response = res.data
  //   })
  //   .catch(e => {
  //     response = [];
  //   })
  return response
}

const getReceivedCommentByUserId = async (userId) => {
  let response = []
  // await axios
  //   .get(
  //     commentUrl + 'receivedByUser/' + userId,
  //     {
  //       withCredentials: true
  //     }
  //   )
  //   .then(res => {
  //     response = res.data
  //   })
  //   .catch(e => {
  //     response = [];
  //   })
  return response
}

export default {
  getRatingById,
  getRatingStatById,
  getSendCommentByUserId,
  getReceivedCommentByUserId
}
