import React, { useCallback, useEffect, useRef, useState } from 'react'
import Footer from '../../components/footer/Footer';
import messageApiService from '../../services/messageApiService';
import './MessageDetail.css'
import { Toast } from 'primereact/toast';
import { format } from 'date-fns';
import { Outlet, Link, useNavigate } from "react-router-dom";
import userApiService from '../../services/userApiService';
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import announcementApiService from '../../services/announcementApiService';
import MessageCard from './components/MessageCard';
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import transactionApiService from '../../services/transactionApiService';
import { t } from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

const MessageDetail = () => {
    const navigate = useNavigate();
    const [ownerOfAnnouncement, setOwnerOfAnnouncement] = useState({
    })
    const toast = useRef(null);
    const { t } = useTranslation();
    const [announcement, setAnnouncement] = useState({})
    const [logUsed, setLogUsed] = useState({})
    const [messageToSend, setMessageToSend] = useState(t('messagePage.clientMessage'))
    const toastMessage = useRef("");
    const { announceId, senderId, ownerOfAnnouncementId } = useParams();

    const [messages, setMessages] = useState([

    ]);

    const getMessageOfDiscussion = async () => {
        let response = await messageApiService.getMessageOfDiscussion(ownerOfAnnouncementId, senderId, announceId);
        if (response?.error) {
            showErrorMessage({
                title: t('errors.problemOccurred'), message: response?.error
            });
        } else {
            setMessages([...response]);

            if (response.length > 2) {
                setMessageToSend("");
            } else if (response.length == 1) {
                if (response[0].message.indexOf(t('messagePage.clientMessage')) <= -1) {
                    setMessageToSend("");
                }
            }
        }
    }

    const parseMessage = () => {
        var lignes = messageToSend.split(/\r?\n/);
        let finalString = "";
        for (var i = 0; i < lignes.length; i++) {
            finalString += lignes[i] + "\n"
        }
        return finalString;
    }

    const sendMessage = async () => {
        if (messageToSend.length > 2) {
            let response = await messageApiService.sendMessage(
                {
                    "announcementId": announceId,
                    "ownerOfAnnouncement": ownerOfAnnouncementId,
                    "interestedUser": senderId,
                    "userWhoSendMessage": logUsed?.id,
                    "message": parseMessage(),
                }
            );
            if (response?.error) {
                showErrorMessage({
                    title: t("errors.problemOccurredForSend"), message: response?.error
                });
            } else {
                setMessages([...messages, response]);
                setMessageToSend("");
            }
        }
    }

    const getUserById = async (userId) => {
        let response = await userApiService.getUserById(userId);
        if (response?.error) {
            showErrorMessage({
                title: t('errors.problemOccurred'), message: response?.error
            });
        } else {
            setOwnerOfAnnouncement(response);
        }
    }

    const getAnnounceById = async (announceId) => {
        let response = await announcementApiService.getAnnounceById(announceId);
        if (response?.error) {
            showErrorMessage({
                title: t('errors.problemOccurredForAnnounce'), message: response?.error
            });
        } else {
            setAnnouncement(response);
        }
    }

    const showErrorMessage = ({ title, message }) => {
        toastMessage.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
    }

    useEffect(() => {
        getUserById(ownerOfAnnouncementId)
        // getMessageOfDiscussion(ownerOfAnnouncementId, senderId, announceId)
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setLogUsed(user);
            setMessageToSend(user.id == ownerOfAnnouncementId ? t('messagePage.ownerMessage') : t('messagePage.clientMessage'))
        }
        getMessageOfDiscussion()
        getAnnounceById(announceId)
    }, [announceId, senderId, ownerOfAnnouncementId])

    const confirmAnnounce = (event, announceId) => {
        confirmPopup({
            target: event.currentTarget,
            message: t('messagePage.areYouSureToValidate'),
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: accept(announceId),
            reject
        });
    };

    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };


    const showSuccessMessage = ({ title, message }) => {
        toast.current.show({ severity: 'success', summary: title, detail: message, life: 3000 });
    }

    const accept = async (announceId) => {
        let response = await transactionApiService.createTransaction({
            announceId: announceId,
            userId: logUsed.id,
        });

        if (response?.error) {
            showErrorMessage({
                message: response?.error,
                title: t('errors.validation')
            })
        } else {
            showSuccessMessage({
                message: t('messagePage.realyTakeInAccount'),
                title: t('messagePage.validateAnnounce')
            })
        }
    };


    return (
        <>
            <div className="profil-message-page overflow-x-hidden page flex flex-column relative" style={{
                minHeight: "100vh"
            }}>
                <Toast ref={toast} />
                <div className="profil-message-container flex p-4 md:p-6">
                    <div className='max-width-container flex-grow-1 mx-auto flex flex-column'>
                        <div className='shadow-2' style={{
                            position: 'fixed',
                            left: 0,
                            width: '100%',
                            background: 'white',
                            zIndex: 5,
                            top: 0
                        }} >
                            <div className='flex p-3 align-items-center  border-bottom-1 border-300'>
                                <i className='cursor-pointer pi pi-arrow-left' onClick={() => { navigate(-1); }}></i>
                                <div className="ml-3 flex align-items-center">
                                    <div className='mr-2'>
                                        <div class="border-circle w-3rem h-3rem m-2 bg-primary font-bold flex align-items-center justify-content-center">{ownerOfAnnouncement?.firstname?.charAt(0) ?? 0}</div>
                                    </div>
                                    <div className='flex-grow-1'>
                                        <h4 className='line-height-3 mt-0 mb-2'>{ownerOfAnnouncement?.firstname}</h4>
                                        <p className='m-0 line-height-3 text-sm'>{t('messagePage.member')} {ownerOfAnnouncement?.createAt ? format(new Date(ownerOfAnnouncement.createAt), 'MMMM yyyy', 'fr') : ''}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='p-3'>
                                {
                                    announcement?.cityWhereHeSent?.name ? (
                                        <h4 className='line-height-3 mt-0 mb-2'>{t('messagePage.cityWhereHeSent', {
                                            cityWhereHeSentName: announcement?.cityWhereHeSent?.name,
                                            cityWhereHeRetrieveName: announcement?.cityWhereHeRetrieve?.name
                                        })} ({announcement?.amountToSend + " " + announcement?.currencyFrom + "    ->    " + announcement?.amountToRetrieve + " " + announcement?.currencyTo})</h4>
                                    ) : (
                                        <h4 className='line-height-3 mt-0 mb-2'>{t("messagePage.exchangeCity", {
                                            name: announcement?.exchangeCity?.name
                                        })}</h4>
                                    )
                                }
                                <p className='m-0 line-height-3 text-sm'>{t('messagePage.publicationDate')} <strong>{announcement?.announceAt ? format(new Date(announcement.announceAt), 'dd MMMM yyyy', 'fr') : ''}</strong> {t('messagePage.validity')} <strong>{announcement?.expiredAt ? format(new Date(announcement.expiredAt), 'dd MMMM yyyy', 'fr') : ''}</strong></p>
                            </div>
                        </div>
                        <div className='h-6rem'></div>
                        <div className='flex-grow-1 py-3' style={{
                            minHeight: "200px",
                            marginTop: "6rem",
                            maxHeight: "800px"
                        }}>
                            {
                                messages.length > 0
                                    ? messages.map((item) => {
                                        return item.userWhoSendMessage == logUsed?.id ? (
                                            <MessageCard
                                                marginLeft='auto'
                                                time={format(item?.sendAt, 'H:mm')}
                                                message={item?.message.split("\n")}
                                                bgColor={'#b9e2fd'}
                                            />
                                        ) : (
                                            <MessageCard
                                                time={format(item?.sendAt, 'H:mm')}
                                                message={item?.message.split("\n")}
                                            />
                                        )
                                    })
                                    : ''}
                        </div>
                        <div className='flex align-items-end justify-content-between'>
                            <InputTextarea autoResize value={messageToSend} onChange={(e) => setMessageToSend(e.target.value)} rows={5} className='flex-grow-1 mr-3' />
                            <div className=''>
                                {/*   */}
                                <Button className="bg-primary border-color-primary" raised label="" icon="pi pi-send" iconPos="right" onClick={() => {
                                    sendMessage()
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={toastMessage} />
                <Footer />
            </div>
        </>

    );
}

export default MessageDetail;
