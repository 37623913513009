import { Outlet, Link } from "react-router-dom";
import logo from '../../logo.svg';
import './Layout.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, createContext, useRef, useEffect, useState } from 'react'
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Steps } from 'primereact/steps';
import { FloatLabel } from 'primereact/floatlabel';
import { Formik } from 'formik'
import * as yup from 'yup'
import { useLocation } from 'react-router-dom';
import {
    Checkbox,
    Divider,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    ListItemText,
    Stack,
    ListSubheader,
    Typography,
    Box
} from '@mui/material'
import { Toast } from 'primereact/toast';
import InputLocationSearch from "../input-location-search/InputLocationSearch";
import announcementApiService from "../../services/announcementApiService";
import LoginModal from "../auth-modal/LoginModal";
import RegisterModal from "../auth-modal/RegisterModal";
import AnnouncementModal from "../announcement-modal/AnnouncementModal";
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import LayoutSidebar from "../sidebar/Sidebar";
import { Sidebar } from "primereact/sidebar";
import { useTranslation, Trans } from 'react-i18next';
import OtpVerificationModal from "../auth-modal/OtpVerificationCodeModal";
import PublishedOfferChoiceModal from "../../pages/home/components/AnnouncementCard/PublishedOfferChoice";
import moneyAnnouncementApiService from "../../services/moneyAnnouncementApiService";
import currencyAnnouncementApiService from "../../services/currencyAnnouncementApiService";

const Layout = () => {
    const { t, i18n } = useTranslation();
    const [loader, setLoader] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState("fr")
    const [languages, setLanguages] = useState([
        {
            name: "FR",
            code: "fr"
        },
        {
            name: "EN",
            code: "en"
        },
    ])
    const [visualisationToastTime, setVisualisationToastTime] = useState(3000)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false)
    const [showRegisterModal, setShowRegisterModal] = useState(false)
    const [showPublishedModalChoice, setShowPublishedModalChoice] = useState(false)
    const [didSaveAction, setDidSaveAction] = useState(false)
    const [showOtpVerificationModal, setShowOtpVerificationModal] = useState(false)
    const [showSearchResult, setShowSearchResult] = useState(false)
    const [userLogged, setUserLogged] = useState(false)
    const [hideHeader, setHideHeader] = useState(false)
    const [offerInProgress, setOfferInProgress] = useState({})
    const profilMenuRef = useRef(null);
    const [visibleLeft, setVisibleLeft] = useState(false);

    const [user, setUser] = useState({})
    const toast = useRef(null);
    const navigate = useNavigate();

    let profilMenuItems = [
        {
            label: t("layout.header.menu.myProfil"),
            icon: 'pi pi-user',
            command: () => {
                navigate('/profil')
            }
        },
        {
            label: t("layout.header.menu.logout"),
            icon: 'pi pi-sign-out',
            command: () => {
                setUser({})
                localStorage.clear()
                sessionStorage.clear()
                navigate('/')
                window.location.reload();
            }
        }
    ];

    const location = useLocation();
    const [previousPath, setPreviousPath] = useState('');

    const loadHeader = () => {
        if (window.location.pathname.indexOf('/message-detail/announce') > -1) {
            setHideHeader(true)
        } else {
            setHideHeader(false)
        }

        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user);
            setUserLogged(true);
        }
    }

    useEffect(() => {
        // Condition pour rafraîchir le layout
        if (shouldRefreshLayout(previousPath, location.pathname)) {
            refreshLayout();
        }

        // Mettre à jour l'URL précédente après chaque navigation
        setPreviousPath(location.pathname);
    }, [location]);

    const refreshLayout = () => {
        // Code pour réactualiser le layout (par exemple, re-fetch des données)
        console.log('Layout refreshed due to route change');
        setHideHeader(false);
    };

    const shouldRefreshLayout = (prevPath, currentPath) => {
        // Remplacez '/page-a' par l'URL que vous souhaitez vérifier
        return prevPath.indexOf('/message-detail/announce') > -1;
    };


    useEffect(() => {
        loadHeader();
    }, [])

    const handleCityToCallback = async (childData) => {
        let response = await announcementApiService.searchAnnoucement(childData);
        if (response?.error) {
            showErrorMessage({
                title: t("errors.problemOccurred"), message: response?.error
            });
        } else {
            sessionStorage.setItem('search-by-zone', 'true')
            sessionStorage.setItem('zone', JSON.stringify(childData))
            sessionStorage.setItem('zones-result', JSON.stringify(response))
            navigate('/search');
            window.location.reload();
        }
    };

    const saveMoneyAnnouncement = async (announce) => {
        let response = await moneyAnnouncementApiService.createAnnoucement(announce);

        if (response?.id) {
            sessionStorage.clear();
            showSuccessMessage({
                title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
            });
            navigate('/profil');
        } else {
            showErrorMessage({
                title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
            });
        }
    }

    const saveCurrencyAnnouncement = async (announce) => {
        let response = await currencyAnnouncementApiService.createAnnoucement(announce);

        if (response?.id) {
            sessionStorage.clear();
            showSuccessMessage({
                title: t('searchPage.announceSave'), message: t('searchPage.announceSaveMessage')
            });
            navigate('/profil');
        } else {
            showErrorMessage({
                title: t('searchPage.announceNotSave'), message: response.error ?? t('searchPage.problemOccurredAtThisLevel')
            });
        }
    }

    const showSuccessMessage = ({ title, message }) => {
        toast.current.show({ severity: 'success', summary: title, detail: message, life: 5000 });
    }

    const showErrorMessage = ({ title, message }) => {
        toast.current.show({ severity: 'error', summary: title, detail: message, life: 5000 });
    }

    return (
        <Box >
            {
                !hideHeader ? (
                    <header className="relative z-5 header w-full bg-white  px-4 md:px-6">
                        <nav className="nav max-width-container mx-auto w-full flex-wrap md:flex-nowrap flex align-items-center md:justify-content-between justify-content-center">
                            <Link to="/" className="w-4 max-w-10rem my-auto"><img src={"/assets/images/soluchange_logo.png"} className="App-logo" alt="logo" /></Link>
                            {
                                userLogged ? (
                                    <>
                                        <ul className="hidden md:flex list-none gap-3 md:gap-5 p-0 flex-wrap md:flex-nowrap align-items-center md:justify-content-end justify-content-center">
                                            <li className="link"></li>
                                            <li className="search w-15rem">
                                                <InputLocationSearch variant="standard" visualStyle={"header"} parentCallback={handleCityToCallback} title={t('layout.header.searchField')} className={""} />
                                            </li>
                                            <li className="search w-6rem">
                                                <Dropdown value={selectedLanguage}
                                                    onChange={(e) => { i18n.changeLanguage(e.value); setSelectedLanguage(e.value) }}
                                                    options={languages} optionLabel="name" optionValue="code"
                                                    className="w-full " />
                                            </li>
                                            <li className="link">
                                                <Link to="/" className="font-medium text-primary">{t('layout.header.menu.home')}</Link>
                                            </li>
                                            <li className="link">
                                                <span className="font-medium text-primary text-primary cursor-pointor" onClick={() => setShowAnnouncementModal(true)}>{t('layout.header.menu.placeAnOffer')}</span>
                                            </li>
                                            <li className="link">
                                                <Link to="/profil-message" className="font-medium text-primary">{t('layout.header.menu.message')}</Link>
                                            </li>
                                            <li className="link" style={{
                                                maxWidth: '11rem'
                                            }}>
                                                <Menu model={profilMenuItems} popup ref={profilMenuRef} id="popup_menu_right" popupAlignment="right" />
                                                <Button link className="font-medium text-primary" onClick={(event) => profilMenuRef.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup >
                                                    <i className="pi pi-user mr-2"></i> {user?.firstname}
                                                </Button>
                                            </li>
                                        </ul>
                                        <ul className="flex md:hidden list-none gap-5 flex p-0  flex-wrap md:flex-nowrap align-items-center md:justify-content-between justify-content-center">
                                            <li className="search w-15rem">
                                                <InputLocationSearch variant="standard" visualStyle={"header"} parentCallback={handleCityToCallback} title={t('layout.header.searchField')} className={""} />
                                            </li>

                                            <li className="link">
                                                <Button raised icon="pi pi-bars" size="small" className="bg-primary border-color-primary" onClick={() => setVisibleLeft(true)} />
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    <ul className="list-none gap-3 md:gap-5 flex p-0  flex-wrap md:flex-nowrap align-items-center md:justify-content-between justify-content-center">
                                        <li className="link"></li>
                                        <li className="search w-13rem md:w-15rem">
                                            <InputLocationSearch variant="standard" visualStyle={"header"} parentCallback={handleCityToCallback} title={t('layout.header.searchField')} className={""} />
                                        </li>
                                        <li className="search w-5rem">
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => {
                                                    setSelectedLanguage(e.target.value)
                                                    i18n.changeLanguage(e.target.value)
                                                }
                                                }
                                                size='small'
                                                labelId="search-select-label-for-currencyTo"
                                                id="search-select-for-currencyTo"
                                                value={selectedLanguage}
                                                label="Options"
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        // Autofocus on textfield
                                                        autoFocus
                                                        placeholder={t("global.changeLanguage")}
                                                        fullWidth
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                // Prevents autoselecting item while typing (default Select behaviour)
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                {languages.map((option, i) => (
                                                    <MenuItem key={i} value={option.code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </li>
                                        <li className="link">
                                            <Link to="/" className="font-medium text-primary">{t('layout.header.menu.home')}</Link>
                                        </li>
                                        <li className="link">
                                            <span className="font-medium text-primary text-primary cursor-pointor" onClick={() => setShowAnnouncementModal(true)}>{t('layout.header.menu.placeAnOffer')}</span>
                                        </li>
                                        <li className="link">
                                            <span className="font-medium text-primary text-primary" onClick={() => setShowRegisterModal(true)}>{t('layout.header.menu.signUp')}</span>
                                        </li>
                                        <li className="link">
                                            <Button size="small" className="bg-primary border-color-primary" label={t('layout.header.menu.login')} onClick={() => setShowLoginModal(true)} raised />
                                        </li>
                                    </ul>
                                )
                            }
                        </nav>
                    </header>
                ) : ''
            }

            <Toast ref={toast} />


            <Sidebar baseZIndex={80} visible={visibleLeft} position="left" onHide={() => setVisibleLeft(false)}>
                <LayoutSidebar
                    languages={languages}
                    language={selectedLanguage}
                    changeLanguage={
                        (value) => {
                            i18n.changeLanguage(value); setSelectedLanguage(value)
                        }
                    }
                    openAnnounceModal={
                        () => {
                            setVisibleLeft(false);
                            setShowAnnouncementModal(true);
                        }
                    }
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                />
            </Sidebar>

            {
                showLoginModal ? <LoginModal
                    showOtpVerification={() => {
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setShowOtpVerificationModal(true);
                    }}
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    visualisationToastTime={visualisationToastTime}
                    onSubmit={(logUser) => {
                        setUser(logUser);
                        setUserLogged(true);
                        if (Object.keys(offerInProgress).includes("rate")) {
                            if (didSaveAction) {
                                offerInProgress.userId = logUser?.id;
                                if (Object.keys(offerInProgress).includes("cityWhereHeRetrieve")) {
                                    saveMoneyAnnouncement(offerInProgress);
                                } else {
                                    saveCurrencyAnnouncement(offerInProgress);
                                }
                            } else {
                                setShowPublishedModalChoice(true);
                            }
                        }
                    }} open={showLoginModal} openCreateModal={() => { setShowLoginModal(false); setShowRegisterModal(true); }} onClose={() => setShowLoginModal(false)} /> : ''
            }

            {
                showAnnouncementModal ? <AnnouncementModal
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    opentLoginModal={(offer) => {
                        setOfferInProgress(offer);
                        setVisualisationToastTime(0);
                        setShowAnnouncementModal(false);
                        setShowPublishedModalChoice(false);
                        setShowRegisterModal(false);
                        setShowLoginModal(true);
                    }}
                    openPublishedModalChoice={(offer) => {
                        setOfferInProgress(offer);
                        setVisualisationToastTime(0);
                        setShowAnnouncementModal(false);
                        setShowLoginModal(false);
                        setShowRegisterModal(false);
                        setShowPublishedModalChoice(true);
                    }}
                    open={showAnnouncementModal}
                    onClose={() => setShowAnnouncementModal(false)}
                /> : ''
            }

            {
                showRegisterModal ? <RegisterModal
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    visualisationToastTime={visualisationToastTime}
                    open={showRegisterModal} onSubmit={(logUser) => {
                        // setUser(logUser);
                        // setUserLogged(true);
                        setShowOtpVerificationModal(true);
                    }}
                    openLoginModal={() => { setShowRegisterModal(false); setShowLoginModal(true); }} onClose={() => setShowRegisterModal(false)} /> : ''
            }

            {
                showOtpVerificationModal ? <OtpVerificationModal
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    visualisationToastTime={visualisationToastTime}
                    open={showOtpVerificationModal} onSubmit={(logUser) => {
                        setUser(logUser);
                        setUserLogged(true);
                        setShowOtpVerificationModal(false);
                        if (Object.keys(offerInProgress).includes("rate")) {
                            if (didSaveAction) {
                                offerInProgress.userId = logUser?.id;
                                if (Object.keys(offerInProgress).includes("cityWhereHeRetrieve")) {
                                    saveMoneyAnnouncement(offerInProgress);
                                } else {
                                    saveCurrencyAnnouncement(offerInProgress);
                                }
                            } else {
                                setShowPublishedModalChoice(true);
                            }
                        }
                    }} onClose={() => setShowOtpVerificationModal(false)} /> : ''
            }

            {
                showPublishedModalChoice ? <PublishedOfferChoiceModal
                    showSuccessMessage={({ title, message }) => {
                        showSuccessMessage({
                            title: title, message: message
                        });
                    }}
                    showErrorMessage={({ title, message }) => {
                        showErrorMessage({
                            title: title, message: message
                        });
                    }}
                    openLoginModal={(offer) => {
                        setVisualisationToastTime(0);
                        setOfferInProgress(offer);
                        setDidSaveAction(true);
                        setShowAnnouncementModal(false);
                        setShowPublishedModalChoice(false);
                        setShowRegisterModal(false);
                        setShowLoginModal(true);
                    }}
                    offer={offerInProgress} open={showPublishedModalChoice} onSubmit={() => {

                    }} onClose={() => setShowPublishedModalChoice(false)} /> : ''
            }
            <Outlet context={{ showSuccessMessage, showErrorMessage }} />

        </Box>
    )
};

export default Layout;
